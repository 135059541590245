import React from "react"
import styled from "styled-components"
import { v4 } from "uuid"

import ProductCard from "../ProductCard"
import NoResult from "../NoResult"

const SectionGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
`
const SectionItem = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 60px;
  &:after {
    display: none;
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background: #fff;
    z-index: 0;
    width: 460px;
    height: 460px;
    border: 60px solid rgba(221, 228, 240, 0.5);
    @media (min-width: 1600px) {
      display: block;
      left: calc(100% - 350px);
    }
    @media (min-width: 1740px) {
      border: 80px solid rgba(221, 228, 240, 0.5);
      left: calc(100% - 290px);
    }
  }
`
const ProductCardList = ({
  data,
  category,
  buildingType,
  buildingApp,
  price,
  roofStyle,
  openQuoteModal,
  limit,
}) => {
  var cateogryProductData = []
  if (category === "All") {
    cateogryProductData = data
  } else {
    data.forEach(function (item) {
      if (item.node.productCategory.name === category) {
        cateogryProductData.push(item)
      }
    })
  }
  var typeProductData = []
  if (buildingType === "All") {
    typeProductData = cateogryProductData
  } else {
    cateogryProductData.forEach(function (item) {
      var flag = 0
      item.node.productSubCategories.forEach(function (node) {
        if (node.name === buildingType) {
          flag = flag + 1
        }
      })
      if (flag > 0) {
        typeProductData.push(item)
      }
    })
  }

  var applicationProductData = []
  if (buildingApp === "All") {
    applicationProductData = typeProductData
  } else {
    typeProductData.forEach(function (item) {
      var flag = 0
      item.node.productApplications.forEach(function (node) {
        if (node.name === buildingApp) {
          flag = flag + 1
        }
      })
      if (flag > 0) {
        applicationProductData.push(item)
      }
    })
  }
  var priceProductData = []
  if (price === "All") {
    priceProductData = applicationProductData
  } else if (price === "0-2000") {
    applicationProductData.forEach(function (item) {
      if (parseInt(item.node.price) >= 0 && parseInt(item.node.price) <= 2000) {
        priceProductData.push(item)
      }
    })
  } else if (price === "2000-5000") {
    applicationProductData.forEach(function (item) {
      if (
        parseInt(item.node.price) > 2000 &&
        parseInt(item.node.price) <= 5000
      ) {
        priceProductData.push(item)
      }
    })
  } else if (price === "5000-20000") {
    applicationProductData.forEach(function (item) {
      if (
        parseInt(item.node.price) > 5000 &&
        parseInt(item.node.price) <= 20000
      ) {
        priceProductData.push(item)
      }
    })
  } else {
    applicationProductData.forEach(function (item) {
      if (parseInt(item.node.price) > 20000) {
        priceProductData.push(item)
      }
    })
  }
  var roofProductData = []
  if (roofStyle === "All") {
    roofProductData = priceProductData
  } else {
    priceProductData.forEach(function (item) {
      if (item.node.productRoofStyle.name === roofStyle) {
        roofProductData.push(item)
      }
    })
  }

  var productData = roofProductData

  return (
    <div>
      <SectionGrid>
        {productData?.length > 0 ? (
          productData?.map((item, i) => {
            if (limit && i >= 10) return null
            return (
              <SectionItem key={v4()}>
                <ProductCard data={item.node} openQuoteModal={openQuoteModal} />
              </SectionItem>
            )
          })
        ) : (
          <NoResult />
        )}
      </SectionGrid>
    </div>
  )
}

export default ProductCardList
